import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import { GatsbyImage } from "gatsby-plugin-image";

const whatWeDoTemplate = ({ data }) => {
	console.log("Read whatWeDo data", data);
	const tags = data.post.tags.nodes;
	console.log(tags);

	return (
		<Layout>
			<div>
				<h1>{data.post.title}</h1>
				<div>
					<h2>Tags:</h2>
					{tags.map((tag) => {
						return (
							<div key={tag.id}>
								<h2>{tag.name}</h2>
							</div>
						);
					})}
				</div>

				<h3>Published data: {data.post.date}</h3>
				<div>
					<GatsbyImage image={data.post.featuredImage.node.GatsbyImage} alt="product-image" />
					<p dangerouslySetInnerHTML={{ __html: data.post.content }} />
				</div>
			</div>
		</Layout>
	);
};

export default whatWeDoTemplate;

export const pageQuery = graphql`
	query ($id: String!) {
		post: wpWhatWeDo(id: { eq: $id }) {
			id
			title
			content
			categories {
				nodes {
					name
					uri
				}
			}
			date(formatString: "DD MM YYYY")
			categories {
				nodes {
					id
					name
					uri
					slug
				}
			}
			tags {
				nodes {
					name
					uri
					id
				}
			}
			featuredImage {
				node {
					gatsbyImage(width: 300)
				}
			}
		}
	}
`;
